<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据中心</el-breadcrumb-item>
        <el-breadcrumb-item>采购系统</el-breadcrumb-item>
        <el-breadcrumb-item>采购订单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h4>采购订单</h4>
    </el-row>
    <el-row>
      <table-searcher
        ref="searcher"
        :params="searcherParams"
        @search="handleSearch"
      >
        <template slot="action">
            <el-button
            type="primary"
            size="small"
            @click="$refs['downloader'].show(getParams())"
          >下载</el-button>
        </template>
      </table-searcher>
    </el-row>
    <!-- <el-row class="search">
      <el-form size="mini" inline>
        <el-form-item>
          <el-date-picker
            size="mini"
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchList()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row> -->
    <god-table
      ref="table"
      prefetch
      paging
      :data-provider="dataProvider"
      height="calc(100vh - 324px)"
    >
      <el-table-column prop="id" label="采购单号"></el-table-column>
      <el-table-column prop="contract_id" label="合同号"></el-table-column>
      <el-table-column prop="vendor_name" label="供应商"></el-table-column>
      <el-table-column prop="user" label="采购员"></el-table-column>
      <el-table-column prop="updated_at" label="订单审核日期"></el-table-column>
      <el-table-column prop="ship_date" label="交期"></el-table-column>
      <el-table-column
        prop="_transport_type"
        label="运输方式"
      ></el-table-column>
      <el-table-column prop="warehouse" label="交付仓库"></el-table-column>
      <el-table-column prop="_invoice_rate" label="预付比例"></el-table-column>
      <el-table-column prop="_invoice_type" label="开票种类"></el-table-column>
      <!-- 为了较好兼容下载功能，返回数据有改动，finance原为数组，现已改变为各元素为单独字段 -->
      <el-table-column
        prop="finance_product_qty"
        label="总数"
      ></el-table-column>
      <el-table-column
        prop="finance_product_ctn"
        label="总箱数"
      ></el-table-column>
      <el-table-column
        prop="finance_product_volume"
        label="总体积"
      ></el-table-column>
      <el-table-column
        prop="finance_product_amount"
        label="采购金额"
      ></el-table-column>
      <el-table-column
        prop="finance_product_received_amount"
        label="到货金额"
      ></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
    </god-table>

    <!-- 导出 -->
    <xlsx-downloader ref="downloader" type="DCENTER_PURCHASE_ORDER" />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import TableSearcher, { formatSearch } from '@/components/table-searcher'
import XlsxDownloader from '@/components/xlsx-downloader'
import { createTableSearcherParams } from './options'
import GodTable, { modifiField } from '@/components/god-table'
import { API } from '@/service/api'

export default {
  components: {
    TableSearcher,
    GodTable,
    XlsxDownloader
  },
  setup () {
    const table = ref(null)
    const searcher = ref(null)
    const searcherParams = createTableSearcherParams()
    const searchQuery = ref(formatSearch(searcherParams))

    const getParams = () => {
      const d = searcher.value ? searcher.value.model : {}
      const model = modifiField(d, 'range', f => {
        return Array.isArray(f)
          ? {
            updated_at_from: f[0],
            updated_at_to: f[1]
          }
          : {}
      })

      searchQuery.value = model
      return searchQuery.value
    }

    return {
      table,
      searcher,

      searcherParams,
      searchQuery,
      getParams,
      handleSearch () {
        table.value.refresh()
      },
      dataProvider (pageData) {
        const params = getParams()
        return API.DataCenter.getPurchaseOrders({ ...params, ...pageData })
      }
    }
  }
}
</script>
