import { cInput, cSelect, cDateRangePicker } from '@/components/form-item-gen'
import store from '@/store'

export function createTableSearcherParams () {
  return [
    // 采购单号
    cInput('order_id', '', {
      attrs: {
        placeholder: '采购单号'
      }
    }),
    cInput('contract_id', '', {
      attrs: {
        placeholder: '合同号'
      }
    }),
    cSelect('vendor_id', null, {
      attrs: {
        placeholder: '供应商',
        filterable: true,
        clearable: true
      },
      options: {
        key: 'id',
        dataProvider: () => store.getters.sysConfig.vendorList,
        attrGetter: it => {
          return {
            label: it.name,
            value: it.id
          }
        }
      }
    }),
    cDateRangePicker('range', [], {
      attrs: {
        'value-format': 'yyyy-MM-dd',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期'
      }
    })
  ]
}
